import { CLEAR, PASS_TOASTER, SET, SET_PROFILE, SET_TOASTER } from '../action/infoType'

const initialState = {
  sidebarShow: true,
  toastMsg: '',
  toast: false,
  profile: false,
  isPassToast: false,
}

const changeState = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case SET:
      console.log(payload, 'response from reducer')
      return { ...state, sidebarShow: payload }
    case SET_TOASTER:
      console.log(state, 'reducer')
      return { ...state, toastMsg: payload, toast: true }
    case SET_PROFILE:
      return { ...state, profile: payload }
    case CLEAR:
      return { ...state, toastMsg: '', toast: false, profile: false }
    case PASS_TOASTER:
      console.log(state, 'reducer')
      return { ...state, isPassToast: payload }
    default:
      return state
  }
}
export default changeState
