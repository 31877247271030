import { RETRIEVE_INFO, RETRIEVE_USERS } from '../action/infoType'

const initialState = {
  info: {},
  emp: [],
  empUser: {},
}
const infoReducer = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case RETRIEVE_INFO:
      console.log(payload)
      return { ...state, info: payload }
    case RETRIEVE_USERS:
      console.log(payload)
      return { ...state, empUser: payload }
    default:
      return state
  }
}
export default infoReducer
